import locales from '@/locales/locales.js'
import store from '@/store/store.js'

const BASE_URL = process.env.VUE_APP_URL;

export default {
  
  prepareQuery (queryMap, hasParametersInUrl) {
    var query = ''
    for (var [key, value] of queryMap.entries()) {
      if (!query && !hasParametersInUrl) {
        query += '?'
      } else {
        query += '&'
      }

      if (value === 0) {
        value = '0'
      } else if (!value) {
        value = ''
      }

      query += key + '=' + this.encodeParam(value)
    }
    return query
  },

  addQueryParams (url, queryMap) {
    let hasParametersInUrl = url.indexOf('?') > 0;
    var query = this.prepareQuery(queryMap, hasParametersInUrl)
    return url + query
  },

  encodeParam (value) {
    if (!value) {
      return ''
    }
    return encodeURIComponent(value)
  },

  getFullUrl(path){
    if (!path){
      path = ''
    }

    if (path.startsWith('/')){
      path = path.substring(1)
    }

    if (!path.startsWith('http')){
      path = BASE_URL + '/' + path
    }

    return path
  },
  
  getLocalisedUrl (url) {
    if (!url) {
      return null
    }

    const locale = locales.getLocale()
    if (url.startsWith(locale.base + '/') || url === locale.base) {
      return url
    }

    if (url.startsWith('/')) {
      url = url.substring(1)
    }

    return locale.base + '/' + url
  },

  getLocalisedOrganizationUrl (organization, path) {
    let organizationUrl = null
    if (organization){
      organizationUrl = organization.identifier
    }
    
    let resultUrl = null
    const locale = locales.getLocale()
    if (!organizationUrl){
      resultUrl = locale.base + '/'
    } else {
      resultUrl = locale.base + '/join/' + organizationUrl
    }

    if (path){
      resultUrl += '/' + path
    }
    return resultUrl
  },

  getCurrentOrganizationPath(path) {
    let currentOrganization = store.getters['session/organizationByLocation']
    return this.getLocalisedOrganizationUrl(currentOrganization, path)
  },
  getPathToEvent(organization, classIdentifier, eventId) {
    return this.getLocalisedOrganizationUrl(organization, 'class/' + classIdentifier + '?event=' + eventId)
  },

  getUrlInLocale (lang, url) {
    if (!lang) {
      return null
    }

    const locale = locales.getLocaleByCode(lang)
    if (!url) {
      return locale.base
    }

    if (url.startsWith('/')) {
      url = url.substring(1)
    }

    return locale.base + '/' + url
  },

  getUrlWithoutLocale () {
    return this.stripLocaleFromPath((window.location.pathname ?? '') + (window.location.search ?? '') + (window.location.hash ?? ''))
  },

  stripLocaleFromPath(path){
    let tmpPath = path
    var re = new RegExp('^\\/(lv|ru)($|\\/|\\?|#)')
    if (re.test(tmpPath)) {
      tmpPath = tmpPath.substring(3)
    }
    return tmpPath
  },

  getLangFromUrl () {
    var locale = null
    var url = window.location.pathname
    var re = new RegExp('^\\/(lv|ru)($|\\/|\\?|#)')
    if (re.test(url)) {
      locale = url.substring(1, 3)
    }
    return locale
  },
  
  isOrganizationPath (path) {
    let tmpPath = this.stripLocaleFromPath(path)
    return tmpPath.startsWith('/join/') ? true : false
  },
  getOrganizationIdentifier (path) {
    if (path && path.includes('/join/')){
      let joinUrlPartLength = 6
      path = path.substring(path.indexOf('/join/') + joinUrlPartLength);
      return path.split('/')[0]
    } else {
      return null
    }
  },

  removeParameters(url){
    if (!url){
      return null
    }
    
    let indexOfQuestionMark = url.indexOf('?')
    if (indexOfQuestionMark > 0){
      url = url.substring(0, indexOfQuestionMark)
    }
    return url
  }
}